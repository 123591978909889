import React from 'react'

const hiddenElementStyles = {
  opacity: 0,
  pointerEvents: 'none',
  clipPath: 'inset(50%)',
  position: 'absolute',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
}

const HiddenIframe = ({ url }) => {
  return (
    <iframe
      src={url}
      title="Video content"
      className="absolute h-full w-full"
      style={hiddenElementStyles}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}

export default HiddenIframe
